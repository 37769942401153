import React, { useEffect, useRef, useState } from "react"
import mapboxgl, { GeoJSONSource } from "mapbox-gl"
import { Row, Col, Label, Input, Button, Container } from "reactstrap"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import * as turf from "@turf/turf"

import "mapbox-gl/dist/mapbox-gl.css"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"

import { VideoPlacesTable } from "./scanningDataHook"
import { GetMapVideo } from "../../components/Map/fetch_func"
import TableVideos from "./TableVideo"
import { ReconstructionModal, ReconstructionMode } from "./ReconstructionModal"
import { TimeSeriesToolBar } from "common/components/TimeSeriesToolBar/TimeSeriesToolBar"

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!

const mapCenterCoor: [number, number] = [100.530737, 13.746173]

let mapInstance: mapboxgl.Map
export const getMapInstance = () => mapInstance

const ScanningData = () => {
  const mapContainer = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<mapboxgl.Map>()
  const [startDate, setStartDate] = useState("2023-01-01T00:00")
  const [endDate, setEndDate] = useState("2024-01-01T00:00")
  const [daysAgo, setDaysAgo] = useState<number>(0)
  const [places, setPlaces] = useState<VideoPlacesTable[]>([])
  const placesRef = useRef<VideoPlacesTable[]>([])
  const [mouseCoordinates, setMouseCoordinates] = useState<[number, number]>([
    0, 0,
  ])
  const [filterOption, setFilterOption] = useState<"dateRange" | "lastNDays">(
    "dateRange"
  )
  const [openReconModal, setOpenReconModal] = useState(false)
  const [selectedScans, setSelectedScans] = useState<VideoPlacesTable[]>([])
  const [reconstructionMode, setReconstructionMode] = useState<ReconstructionMode>(ReconstructionMode.single)

  const geocoderContainer = useRef<HTMLDivElement>(null);
  const drawContainer = useRef<HTMLDivElement>(null);

  const initMap = () => {
    if (map) return
    const mapInit = new mapboxgl.Map({
      container: mapContainer.current || "",
      style: "mapbox://styles/mapbox/satellite-streets-v12",
      center: mapCenterCoor,
      zoom: 10,
      pitch: 45,
      accessToken: MAPBOX_TOKEN,
    }) as mapboxgl.Map
    setMap(mapInit)
    mapInstance = mapInit
    // Add mouse move event listener to update mouseCoordinates
    mapInit.on("mousemove", e => {
      setMouseCoordinates([e.lngLat.lng, e.lngLat.lat])
    })
  }

  const handleRightClick = () => {
    if (map && mouseCoordinates) {
      const [longitude, latitude] = mouseCoordinates
      const formattedLatitude = latitude.toFixed(4)
      const formattedLongitude = longitude.toFixed(4)

      const coordinatesString = `${formattedLatitude}, ${formattedLongitude}`

      const copyCoordinatesToClipboard = () => {
        navigator.clipboard
          .writeText(coordinatesString)
          .then(() => {
            alert(`Coordinate copied to clipboard: ${coordinatesString}`)
            popup.remove()
          })
          .catch(error => {
            console.error("Failed to copy coordinates to clipboard:", error)
          })
      }

      // Create a new popup
      const popup = new mapboxgl.Popup({
        closeOnClick: false,
        className: "custom-popup",
      })
        .setLngLat(mouseCoordinates)
        .setHTML(
          `
        <div>
        </p>
        <button class="coordinates-button">${coordinatesString}</button>
        </div>`
        )
        .addTo(map)

      // Attach click event listener to the button
      const button = popup.getElement().querySelector(".coordinates-button")
      if (button) {
        button.addEventListener("click", copyCoordinatesToClipboard)
      }

      // Close the popup on any map interaction (click, drag, or zoom)
      map.on("mousedown", () => {
        popup.remove()
      })

      map.on("dragstart", () => {
        popup.remove()
      })

      map.on("zoomstart", () => {
        popup.remove()
      })
    }
  }

  const closeModal = () => {
    setOpenReconModal(!openReconModal)
  }

  const toggleReconModal = () => {
    setOpenReconModal(!openReconModal)
    setReconstructionMode(ReconstructionMode.single)
  }

  const toggleMultiRecon = () => {
    if (selectedScans.length === 0) {
      alert("Please select some scanning data")
      return
    }
    setOpenReconModal(!openReconModal)
    setReconstructionMode(ReconstructionMode.multi)
  }

  const handleMarkerClick = (marker: VideoPlacesTable | null) => {
    if (!marker) return
    const selectedVideo = placesRef.current.find((place) => place._id === marker._id)
    if (!selectedVideo) return
    setSelectedScans([selectedVideo])
    toggleReconModal()
  }

  const handleScanSelect = (inputScan: VideoPlacesTable) => {
    const scanIndex = selectedScans.findIndex(scan => scan._id === inputScan._id)
    if (scanIndex !== -1) {
      const newScans = selectedScans.toSpliced(scanIndex, 1)
      setSelectedScans(newScans)
      return
    }
    const newScans = selectedScans.concat(inputScan)
    setSelectedScans(newScans)
  }

  const initMarkers = () => {
    if (!map) return
    map.on("load", () => {
      // Add clustering layer
      map.addSource("markers", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points
        clusterRadius: 50, // Radius of each cluster when clustering points
      })

      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "markers",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#51bbd6",
            100,
            "#f1f075",
            750,
            "#f28cb1",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      })

      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "markers",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      })

      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "markers",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": "#11b4da",
          "circle-radius": 6,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      })

      // Add click event listener to markers
      map.on("click", "unclustered-point", e => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["unclustered-point"],
        })
        if (features.length) {
          const clickedMarker = features[0].properties as VideoPlacesTable
          handleMarkerClick(clickedMarker)
        }
      })

      const drawPolygon = () => {
        const copyAllLocation = () => {
          //@ts-ignore
          const drawFeatures = map.getSource("markers")._data.features
          const drawnPolygon: any = draw.getAll().features[0].geometry

          const featuresWithinPolygon = drawFeatures.filter((feature: any) => {
            const point = turf.point(feature.geometry.coordinates)
            return turf.booleanPointInPolygon(point, drawnPolygon)
          })

          let urls = ""

          featuresWithinPolygon.forEach((feature: any) => {
            const { download_url } = feature.properties
            urls += `${download_url}\n`
          })

          navigator.clipboard
            .writeText(urls)
            .then(() => {
              const count = featuresWithinPolygon.length
              alert(
                `${count} URLs within the drawn polygon have been copied to the clipboard:\n\n${urls}\n\n`
              )
            })
            .catch(error => {
              console.error("Failed to copy URLs to clipboard:", error)
            })
        }
        const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
        })
        // map.addControl(geocoder)
        geocoderContainer.current?.appendChild(geocoder.onAdd(map))

        const draw = new MapboxDraw({
          controls: {
            point: false,
            line_string: false,
            polygon: true,
            trash: true,
            combine_features: false,
            uncombine_features: false,
          },
          // defaultMode: "draw_polygon",
        })
        drawContainer.current?.appendChild(draw.onAdd(map))
        map.on("draw.create", () => {
          copyAllLocation()
        })
      }
      drawPolygon()
    })
  }

  const updateClusters = async (
    startDate: string,
    endDate: string,
    lastDate: number
  ) => {
    if (!map) return
    GetMapVideo()
      .then(data => {
        setPlaces(data)
        placesRef.current = data
      })
      .catch(error => console.error("Error fetching data:", error))

    const startDateTime = new Date(startDate).getTime()
    const endDateTime = new Date(endDate).getTime()

    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() - lastDate)
    const currentDateTimeStamp = currentDate.getTime()

    const features = places
      .filter((d: VideoPlacesTable) => {
        const createdDateTime = new Date(d.created_at).getTime()
        if (filterOption === "dateRange") {
          return (
            createdDateTime >= startDateTime && createdDateTime <= endDateTime
          )
        } else {
          return (
            createdDateTime >= currentDateTimeStamp &&
            createdDateTime <= Date.now()
          )
        }
      })
      .map((d: VideoPlacesTable) => ({
        type: "Feature",
        properties: d,
        geometry: d.startlocation,
      }))
    if (!features) return
    let formatFeatures = features as GeoJSON.Feature[]

    // map.on('load')
    let markerSource = map?.getSource("markers") as GeoJSONSource
    if (!markerSource) return
    markerSource.setData({
      type: "FeatureCollection",
      features: formatFeatures,
    })
  }

  const handleDateChange = (
    type: "startDate" | "endDate",
    value: string
  ) => {
    if (type === "startDate") {
      setStartDate(value)
    } else {
      setEndDate(value)
    }
    setFilterOption("dateRange")
    setSelectedScans([])
  }

  const handleDaysAgoChange = (newDays: number) => {
    setDaysAgo(newDays)
    setFilterOption("lastNDays")
    setSelectedScans([])
  }

  useEffect(() => {
    // When the component mounts, initialize the map
    initMap();
  }, []);

  useEffect(() => {
    initMarkers();
    updateClusters(startDate, endDate, daysAgo);
  }, [map, startDate, endDate, daysAgo])

  const mapToolBar = () => (<Row className="ms-2">
    <Col className="border-end">
      <Label className="col-form-label">
        Location
      </Label>
      <div ref={geocoderContainer} />
    </Col>
    <Col className="drawContainer">
      <div className="d-flex justify-content-center align-items-center h-100" ref={drawContainer} />
    </Col>
  </Row>)

  return (
    <div>
      <TimeSeriesToolBar
        startDate={startDate}
        endDate={endDate}
        daysAgo={daysAgo}
        onDateChange={handleDateChange}
        onDaysAgoChange={handleDaysAgoChange}
      >
        {mapToolBar()}
      </TimeSeriesToolBar>
      <ReconstructionModal
        scans={selectedScans}
        mode={reconstructionMode}
        isOpen={openReconModal}
        toggleModal={closeModal}
      />
      <div
        ref={mapContainer}
        style={{ height: "75vh", marginTop: "70px" }}
        onContextMenu={handleRightClick}
        className="mb-2"
      />
      <Container >
        <Button color="primary" onClick={toggleMultiRecon}>Multi Session Reconstruction</Button>
      </Container>
      <TableVideos
        daysToCheck={daysAgo}
        startDate={new Date(startDate)}
        endDate={new Date(endDate)}
        filterOption={filterOption}
        places={places}
        onActionClick={handleMarkerClick}
        selectedScans={selectedScans}
        onScanSelect={handleScanSelect}
      />
    </div>
  )
}

ScanningData.displayName = "ScanningData"

export default ScanningData
