import React, { useEffect, useRef, useState } from "react"
import mapboxgl, { LngLatLike } from "mapbox-gl"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import * as turf from "@turf/turf"
import {
  Card,
  CardHeader,
  CardText,
  Container,
} from "reactstrap"

import "mapbox-gl/dist/mapbox-gl.css"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"

import { calculateMidpoint } from "../../components/Map/calculate_mid"

import TablePointCloud from "./table_pc"
import CoorInput from "./coor_Input"
import { Firebase_Token } from "../../firebase_token"
import { Reconstruction, useReconstructionsData } from "modules/ScanningData/scanningDataHook"
import { PointCloudModal } from "./pointCloudModal"
import { PointCloudPlace, usePlacesData } from "common/hooks/placeHook"
import { TimeSeriesToolBar } from "common/components/TimeSeriesToolBar/TimeSeriesToolBar"

const filterReconstruction = (
  recons: Reconstruction[],
  filterOption: "dateRange" | "lastNDays",
  startDate: string,
  endDate: string,
  lastNDays: number,
) => {
  const filteredRecon = recons.filter((recon: Reconstruction) => {
    if (!recon.created_at) return false
    const createdDateTime = new Date(recon.created_at).getTime()
    if (filterOption === "dateRange") {
      const startDateTime = new Date(startDate).getTime()
      const endDateTime = new Date(endDate).getTime()
      return (
        createdDateTime >= startDateTime && createdDateTime <= endDateTime
      )
    } else {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() - lastNDays)
      const currentDateTimeStamp = currentDate.getTime()
      return (
        createdDateTime >= currentDateTimeStamp &&
        createdDateTime <= Date.now()
      )
    }
  })
  return filteredRecon
}

export default function PointCloud() {
  // @ts-ignore
  // eslint-disable-next-line import/no-webpack-loader-syntax
  mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!

  const mapCenterCoor: LngLatLike = [100.5650766862497, 13.756257597720502]
  const mapContainer = useRef<HTMLDivElement>(null)
  const [mouseCoordinates, setMouseCoordinates] = useState<[number, number]>([
    0, 0,
  ])
  const [area, setArea] = useState(0)
  const [places] = usePlacesData()
  const [reconstructions] = useReconstructionsData()
  const [startDate, setStartDate] = useState("2023-01-01T00:00")
  const [endDate, setEndDate] = useState("2024-01-01T00:00")
  const [daysAgo, setDaysAgo] = useState<number>(0)
  const [filterOption, setFilterOption] = useState<"dateRange" | "lastNDays">(
    "dateRange"
  )
  const [modalOpen, setModalOpen] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [selectedPlace, setSelectedPlace] = useState<PointCloudPlace>()
  const [selectedReconstruction, setSelectedReconstruction] = useState<Reconstruction>()
  const [map, setMap] = useState<mapboxgl.Map>()
  const [coor, setCoor] = useState<[number, number][]>()
  const coorRef = useRef<[number, number][]>()
  const firebaseToken: string = Firebase_Token()

  const filteredReconstruction = filterReconstruction(
    reconstructions,
    filterOption,
    startDate,
    endDate,
    daysAgo
  )

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const onSubmitSuccess = (area_name: string) => {
    if (!map || !coor) return
    var coordinates = coor
    var midpoint = calculateMidpoint(coordinates)
    var marker = new mapboxgl.Marker().setLngLat(midpoint).addTo(map)
    var popup = new mapboxgl.Popup({ offset: [0, -30] }).setText(area_name)
    marker.setPopup(popup)
    map.addSource?.("custom-layer", {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [coordinates],
        },
        properties: {}
      },
    })

    map.addLayer?.({
      id: "custom-layer-fill",
      type: "fill",
      source: "custom-layer",
      layout: {},
      paint: {
        "fill-color": "#FF0000",
        "fill-opacity": 0.5,
      },
    })

    map.addLayer?.({
      id: "custom-layer-outline",
      type: "line",
      source: "custom-layer",
      layout: {},
      paint: {
        "line-color": "#000000",
        "line-width": 3,
      },
    })

    toggleModal()
  }

  const initMap = () => {
    const attachMap = (
      setMap: React.Dispatch<React.SetStateAction<any>>,
      mapContainer: React.RefObject<HTMLDivElement>
    ) => {
      const mapInit = new mapboxgl.Map({
        container: mapContainer.current || "",
        style: "mapbox://styles/mapbox/satellite-streets-v12",
        center: mapCenterCoor,
        zoom: 5,
      })
      setMap(mapInit)
      // Add mouse move event listener to update mouseCoordinates
      mapInit.on("mousemove", e => {
        setMouseCoordinates([e.lngLat.lng, e.lngLat.lat])
      })

      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
      })
      mapInit.addControl(geocoder)
    }
    !map && attachMap(setMap, mapContainer)
  }

  const addDrawToMap = () => {
    if (!map) return
    const draw = new MapboxDraw({
      controls: {
        point: false,
        line_string: false,
        polygon: true,
        trash: true,
        combine_features: false,
        uncombine_features: false,
      },
    })
    map.addControl(draw)

    const updateArea = () => {
      const geoJSON = draw.getAll()

      if (geoJSON.features.length > 0) {
        const calculatedArea = turf.area(geoJSON)
        const roundedArea = Math.round(calculatedArea * 100) / 100
        // @ts-ignore
        const coordinates = geoJSON.features[0].geometry.coordinates[0] as [number, number][]
        setArea(roundedArea)
        setCoor(coordinates)
        coorRef.current = coordinates
      }
    }
    map.on("draw.create", updateArea)
  }

  const handleRightClick = () => {
    if (map && mouseCoordinates) {
      const [longitude, latitude] = mouseCoordinates
      const formattedLatitude = latitude.toFixed(4)
      const formattedLongitude = longitude.toFixed(4)

      const coordinatesString = `${formattedLatitude}, ${formattedLongitude}`

      const copyCoordinatesToClipboard = () => {
        navigator.clipboard
          .writeText(coordinatesString)
          .then(() => {
            alert(`Coordinate copied to clipboard: ${coordinatesString}`)
            popup.remove()
          })
          .catch(error => {
            console.error("Failed to copy coordinates to clipboard:", error)
          })
      }

      // Create a new popup
      const popup = new mapboxgl.Popup({
        closeOnClick: false,
        className: "custom-popup",
      })
        .setLngLat(mouseCoordinates)
        .setHTML(
          `
        <div>
        </p>
        <button class="coordinates-button">${coordinatesString}</button>
        </div>`
        )
        .addTo(map)

      // Attach click event listener to the button
      const button = popup.getElement().querySelector(".coordinates-button")
      if (button) {
        button.addEventListener("click", copyCoordinatesToClipboard)
      }

      // Close the popup on any map interaction (click, drag, or zoom)
      map.on("mousedown", () => {
        popup.remove()
      })

      map.on("dragstart", () => {
        popup.remove()
      })

      map.on("zoomstart", () => {
        popup.remove()
      })
    }
  }

  const reconMarker = () => {
    if (!map) return
    filteredReconstruction.forEach((recon: Reconstruction) => {
      var coordinate = recon.scan_data[0].startlocation.coordinates
      var marker = new mapboxgl.Marker({
        color: "#ded71b"
      }).setLngLat(coordinate).addTo(map)

      function displayCreateForm() {
        if (!coorRef.current) {
          alert("Please draw point cloud area before creation")
        }
        setIsUpdate(false)
        setSelectedReconstruction(recon)
        toggleModal()
      }

      // Add event listener to the marker
      marker.getElement()?.addEventListener("click", displayCreateForm)
    })
  }

  const markers = () => {
    if (!map) return
    places.forEach((place: PointCloudPlace) => {
      var coordinates = place.location?.coordinates[0]
      if (!coordinates) return
      var midpoint = calculateMidpoint(coordinates)
      var marker = new mapboxgl.Marker().setLngLat(midpoint).addTo(map)

      // Function to display the form when marker is clicked
      function displayForm() {
        setSelectedPlace(place)
        setIsUpdate(true)
        toggleModal()
      }

      // Add event listener to the marker
      marker.getElement()?.addEventListener("click", displayForm)
    })

    map.on("load", () => {
      map.addSource("areas", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: places.map((place: any) => ({
            type: "Feature",
            properties: {},
            geometry: place.location,
          })),
        },
      })

      map.addLayer({
        id: "areas-fill",
        type: "fill",
        source: "areas",
        layout: {},
        paint: {
          "fill-color": "#0080ff",
          "fill-opacity": 0.5,
        },
      })

      map.addLayer({
        id: "areas-outline",
        type: "line",
        source: "areas",
        layout: {},
        paint: {
          "line-color": "#FF339C",
          "line-width": 3,
        },
      })
    })
  }

  useEffect(() => {
    initMap();
  }, []);

  useEffect(() => {
    if (map) {
      addDrawToMap()
    }
  }, [map])


  useEffect(() => {
    if (!map) return
    markers()
  }, [places])

  useEffect(() => {
    if (!map) return
    reconMarker()
  }, [filteredReconstruction])

  const handleDateChange = (
    type: "startDate" | "endDate",
    value: string
  ) => {
    if (type === "startDate") {
      setStartDate(value)
    } else {
      setEndDate(value)
    }
    setFilterOption("dateRange")
  }

  const handleDaysAgoChange = (newDays: number) => {
    // debugger
    setDaysAgo(newDays)
    setFilterOption("lastNDays")
  }

  // if (!map) return

  return (
    <div>
      <PointCloudModal
        isOpen={modalOpen}
        isUpdate={isUpdate}
        reconstructionsData={filteredReconstruction}
        reconstruction={selectedReconstruction}
        basePointCloud={selectedPlace}
        onSubmit={onSubmitSuccess}
        toggleModal={toggleModal}
        coordinates={coor}
      />
      <TimeSeriesToolBar
        startDate={startDate}
        endDate={endDate}
        daysAgo={daysAgo}
        onDateChange={handleDateChange}
        onDaysAgoChange={handleDaysAgoChange}
      />
      <div
        ref={mapContainer}
        style={{ height: "70vh", marginTop: "70px" }}
        onContextMenu={handleRightClick}
      />
      <Container>
        <Card className="text-center" style={{ marginBottom: "70px" }}>
          <CardHeader>
            <CoorInput
              map={map}
            />
            <strong>{area}</strong>
            <CardText>square meters</CardText>
          </CardHeader>
          <TablePointCloud
            map={map}
            places={places}
          />
        </Card>
      </Container>
    </div>
  )
}
