import { Col, Input, Label, Row } from "reactstrap"
import "./timeSeriesToolBar.scss"

interface TimeSeriesToolBarProps {
    startDate: string
    endDate: string
    daysAgo: number
    onDateChange: (type: "startDate" | "endDate", val: string) => void
    onDaysAgoChange: (val: number) => void
    children?: React.ReactNode
}

const TimeSeriesToolBar = ({
    startDate,
    endDate,
    daysAgo,
    onDateChange,
    onDaysAgoChange,
    children,
}: TimeSeriesToolBarProps) => {
    return <div className="videoMenuContainer bg-white rounded p-2">
        <Row >
            <Col className="textContainer">
                <Label className="col-form-label">
                    Start: Date and time
                </Label>
                <Input
                    type="datetime-local"
                    min="2023-01-01T00:00"
                    id="datetime-local-input1"
                    value={startDate}
                    onChange={e => {
                        onDateChange("startDate", e.target.value)
                    }}
                />
            </Col>
            <Col className="textContainer">
                <Label className="col-form-label">
                    End: Date and time
                </Label>
                <Input
                    type="datetime-local"
                    min="2023-01-01T00:00"
                    id="datetime-local-input2"
                    value={endDate}
                    onChange={e => {
                        onDateChange("endDate", e.target.value)
                    }}
                />
            </Col>
            <Col className="textContainer">
                <Label className="col-form-label">
                    Number of * days ago
                </Label>
                <Input
                    type="number"
                    min="0"
                    id="days-ago-input"
                    value={daysAgo.toString()}
                    onChange={event => onDaysAgoChange(parseInt(event.target.value))}
                />
            </Col>
        </Row>
        {children}
    </div>
}

export { TimeSeriesToolBar }
