import React, { useState } from "react"
import {
    Input,
    Button,
    ModalFooter,
    ModalBody,
    CardBody,
    ModalHeader,
    Modal,
    Label,
    Row,
    Col,
    Container,
} from "reactstrap"
import { PipelineType, Reconstruction, VideoPlacesTable, useReconstruction } from "./scanningDataHook"
import { LabeledInput } from "components/Common/form"

enum ReconstructionMode {
    single = "single",
    multi = "multi",
}

interface ReconstructionModalProps {
    scans: VideoPlacesTable[]
    isOpen: boolean
    toggleModal: () => void
    mode: ReconstructionMode
}



const ReconstructionModal = ({
    scans,
    isOpen,
    toggleModal,
    mode,
}: ReconstructionModalProps) => {
    const {
        pipelines,
        reconstruction,
        reconstructionsData,
        updateReconstruction,
        submitReconstruction,
    } = useReconstruction(mode, scans)
    const [enableSubmit, setEnableSubmit] = useState(true)

    const selectedPipeline = pipelines.find(pipeline => pipeline.id === reconstruction?.pipeline_id)
    const scan = scans[0]

    return <Modal isOpen={isOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Scanning Data</ModalHeader>
        <Container fluid>
            {
                mode === ReconstructionMode.single ? (
                    <Row>
                        <Col>
                            <p><b>Name: </b>{scan?.display_name}</p>
                            <p><b>Storage URL: </b><a href={scan?.download_url}>{scan?.download_url}</a></p>
                            <p><b>User ID: </b>{scan?.user_id}</p>
                            <p><b>Created At: </b>{(new Date(scan?.created_at || Date.now()).toLocaleDateString())}</p>
                        </Col>
                    </Row>
                ) :
                    <>
                        {
                            scans?.map(scan => (
                                <Row key={scan._id}>
                                    <Col lg={6}>
                                        <p><b>ID: </b>{scan?._id}</p>
                                    </Col>
                                    <Col lg={6}>
                                        <p><b>Name: </b>{scan?.display_name}</p>
                                    </Col>
                                </Row>
                            ))
                        }
                    </>
            }

        </Container>
        <ModalBody>
            <CardBody>
                <Label>
                    Select Pipeline
                </Label>
                <Input
                    type="select"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        updateReconstruction("pipeline_id")(e.target.value)
                    }}
                    value={reconstruction?.pipeline_id}
                >
                    {pipelines.map((pipeline) => (
                        <option key={pipeline.id} value={pipeline.id}>
                            {pipeline.name}
                        </option>
                    ))}
                </Input>
                <LabeledInput
                    type="text"
                    title="Organization ID"
                    value={reconstruction?.organization_id}
                    onChange={updateReconstruction("organization_id")}
                />
                {
                    selectedPipeline?.type === PipelineType.Merge && <>
                        <Label>
                            Parent Reconstruction ID
                        </Label>
                        <Input
                            type="text"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                updateReconstruction("parent_id")(e.target.value)
                            }}
                            value={reconstruction?.parent_id}
                            list="reconstructionsDataList"
                        >
                        </Input>
                        <datalist id="reconstructionsDataList">
                            {reconstructionsData.map((reconstructionData) => (
                                <option key={reconstructionData.id} value={reconstructionData.id}>
                                    {reconstructionData.scan_data[0].display_name} - {(new Date(reconstructionData?.created_at || "")).toLocaleString()}
                                </option>
                            ))}
                        </datalist>
                    </>
                }
                <LabeledInput
                    type="number"
                    title="Volume size (Gi)"
                    value={parseInt(reconstruction?.volume_size.slice(0, -2) || "0")}
                    onChange={(value) => {
                        updateReconstruction("volume_size")(parseInt(value))
                    }}
                />
                <LabeledInput
                    type="number"
                    title="Max keypoints"
                    value={reconstruction?.max_keypoints}
                    onChange={(value) => {
                        updateReconstruction("max_keypoints")(parseInt(value))
                    }}
                />
                <LabeledInput
                    type="number"
                    title="Retrieval top k"
                    value={reconstruction?.retrieval_top_k}
                    onChange={(value) => {
                        updateReconstruction("retrieval_top_k")(parseInt(value))
                    }}
                />
                <LabeledInput
                    type="number"
                    title="Downsample frame rate"
                    value={reconstruction?.downsample_frame_rate}
                    onChange={(value) => {
                        updateReconstruction("downsample_frame_rate")(parseInt(value))
                    }}
                />
            </CardBody>
        </ModalBody>
        <ModalFooter className="justify-content-center">
            <Button
                color="primary"
                disabled={!enableSubmit}
                onClick={() => {
                    if (!reconstruction?.pipeline_id) {
                        alert("please select pipeline")
                        return
                    }
                    setEnableSubmit(false)
                    submitReconstruction().then(res => {
                        if (res) {
                            alert("submit recon success")
                        } else {
                            alert("submit recon failed")
                        }
                        setEnableSubmit(true)
                    })
                }}
                style={{ marginBottom: "50px" }}
            >
                Submit
            </Button>
        </ModalFooter>
    </Modal>
}

export { ReconstructionModal, ReconstructionMode }
