import * as THREE from 'three'
import { Suspense, useEffect, useState } from 'react'
import { Canvas, useLoader } from '@react-three/fiber'
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader'
import { OrbitControls } from '@react-three/drei'
import { calculateCoordinateFromMovement, calculatePositionFromCoordinate, useStaticMap } from './coordinateHook'
import { PointCloudPlace } from 'common/hooks/placeHook'
import { ToolsBar } from 'common/components/ToolBar/ToolBar'
import { ControlMode, Controls } from 'common/components/r3f/TransformControl'


const PointCloud = ({
    place,
    localTransform,
    onBoundingBoxAvailable,
}: {
    place: PointCloudPlace
    localTransform: THREE.Vector3
    onBoundingBoxAvailable: (bbox: THREE.Box3) => void
}) => {
    const result = useLoader(PLYLoader, place.signed_public_point_cloud_file || "")
    const material = new THREE.PointsMaterial({
        vertexColors: true,
        size: 0.05,
    });
    const point = new THREE.Points(result, material);
    result.computeBoundingBox()
    if (result.boundingBox) {
        onBoundingBoxAvailable(result.boundingBox)
    }
    const rotation = new THREE.Quaternion(
        place.originCoordinate?.displayRotation.x || 0,
        place.originCoordinate?.displayRotation.y || 0,
        place.originCoordinate?.displayRotation.z || 0,
        place.originCoordinate?.displayRotation.w || 0,
    )
    return <group
        name={place._id}
        position={localTransform}
        quaternion={rotation}
    >
        <primitive object={point} />
    </group>
};

interface MapModelProps {
    place: PointCloudPlace
    mapImage: string
    scale: number
    positionY: number
}

const MapModel = ({ mapImage, scale, positionY }: MapModelProps) => {
    const texture = useLoader(THREE.TextureLoader, mapImage)
    const textureBottom = texture.clone()
    textureBottom.wrapS = THREE.RepeatWrapping
    textureBottom.repeat.x = -1
    texture.colorSpace = THREE.SRGBColorSpace
    const positionVector = new THREE.Vector3(0, positionY, 0)
    const rotation = new THREE.Quaternion(
        0.5, -0.5, 0.5, 0.5
    )
    const scaleVector = new THREE.Vector3(scale, scale, scale)
    return (
        <mesh
            position={positionVector}
            quaternion={rotation}
            scale={scaleVector}
        >
            <boxGeometry args={[texture.image.width, texture.image.height, 0]} />
            <meshStandardMaterial attach="material-5" args={[{ map: texture }]} transparent={true} opacity={0.9} alphaTest={0.9} />
            <meshStandardMaterial attach="material-4" args={[{ map: textureBottom }]} transparent={true} opacity={0.2} alphaTest={0.2} />
        </mesh>
    )
}

interface OwnProps {
    origin: number[]
    place: PointCloudPlace
    selectedControlMode: ControlMode
    onPlaceUpdate: (place: PointCloudPlace) => void
}


type CoordinateEditorProps = OwnProps

const CoordinateEditor = ({ origin, place, onPlaceUpdate, selectedControlMode }: CoordinateEditorProps) => {
    const [pointCloudBoundingBox, setPointCloudBoundingBox] = useState<THREE.Box3>()
    const { scale: mapScale, image: mapImage, updateStaticMap } = useStaticMap(place, pointCloudBoundingBox)
    const [localTransform, setLocalTransform] = useState(new THREE.Vector3())
    const [mapTransform, setMapTransform] = useState(new THREE.Vector3())

    const updatePlaceFromTransform = (object: THREE.Object3D) => {
        if (!origin) return
        const transformedCoordinate = calculateCoordinateFromMovement(origin, object.position)
        const newPlace: PointCloudPlace = {
            ...place,
            originCoordinate: {
                ...place.originCoordinate,
                displayOriginCoordinates: transformedCoordinate,
                displayRotation: {
                    w: object.quaternion.w,
                    x: object.quaternion.x,
                    y: object.quaternion.y,
                    z: object.quaternion.z,
                },
            }
        }
        setLocalTransform(object.position.clone())
        onPlaceUpdate(newPlace)
    }

    const updateTransformFromPlace = () => {
        if (!place.originCoordinate?.displayOriginCoordinates) return
        const newPosition = calculatePositionFromCoordinate(origin, place.originCoordinate?.displayOriginCoordinates)
        setLocalTransform(new THREE.Vector3(
            newPosition[0],
            newPosition[1],
            newPosition[2],
        ))
    }

    useEffect(() => {
        updateTransformFromPlace()
    }, [place.originCoordinate?.displayOriginCoordinates])

    useEffect(() => {
        setLocalTransform(new THREE.Vector3(
            0, place.originCoordinate?.displayOriginCoordinates[2] || 0, 0
        ))
    }, [])

    return (
        <>
            {/* <ToolsBar
                onControlModeChange={}
            /> */}
            <Canvas
                camera={{ fov: 75, near: 0.1, far: 1000, position: [10, 5, 40] }}
                // style={{ borderRadius: "4px" }}
                onPointerMissed={() => { }}
                gl={{ antialias: true, toneMapping: THREE.NoToneMapping, localClippingEnabled: true }}
            >
                <color attach="background" args={['#242424']} />
                <ambientLight intensity={3} />
                <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
                <pointLight position={[-10, -10, -10]} />
                <OrbitControls
                    makeDefault
                    enableDamping={false}
                />
                <Controls
                    controlMode={selectedControlMode}
                    selectedObjectId={place._id}
                    onObjectChange={(object) => { updatePlaceFromTransform(object) }}
                />
                <Suspense fallback={null}>
                    {
                        <PointCloud
                            place={place}
                            localTransform={localTransform}
                            onBoundingBoxAvailable={(bbox) => { setPointCloudBoundingBox(bbox) }}
                        />
                    }
                    {
                        mapImage && <MapModel
                            place={place}
                            mapImage={mapImage}
                            scale={mapScale}
                            positionY={0}
                        />
                    }
                </Suspense>
            </Canvas>
        </>
    )
}

CoordinateEditor.displayName = "CoordinateEditor"

export { CoordinateEditor }
